import { FlowNames } from 'src/app/Constants';
import CommonUtility from 'src/app/CommonUtility';
import LogoutService from 'src/service/LogoutService';
import { buildHsidPath } from 'src/utils/urlUtility';
import { logger } from 'src/app/Logger';
import { OBAService } from 'src/service/Walterson';

/**
 * Perform a Ping logout and send the user to a destination URL upon being completely logged out
 *
 * @param {string | URL} targetUrl An absolute URL that a session will land on after being logged out
 * @param {boolean} replace `true` to replace the current history entry with the logout URL, `false` to add a new one
 * @param {boolean} useAikyamEndpoint `true` to use Aikyam's domain for clear out the OBA Session on Logout, `false` to use walterson's domain
 */
export const logout = (targetUrl, useAikyamEndpoint = false, replace = true) =>
    OBAService.clearIDPSessionOnLogOut(useAikyamEndpoint)
        .then(() => {
            LogoutService.getPingLogoutUrl(String(targetUrl)).then(response => {
                window.location[replace ? 'replace' : 'assign'](response.data);
            });
        })
        .catch(error => {
            logger.error('Failed to log out user', { error });
        });
const pingAccessLogout = (history, queryParams, portalBrand, lang, useAikyamEndpoint = false) => {
    // will go to secure path after logout this way ping access session created
    // for signin after logging user out
    const secureUrl = CommonUtility.formURL(
        window.location.origin,
        buildHsidPath({
            includeBase: true,
            flowName: FlowNames.AUTH,
            portalBrand,
            lang,
        }),
        queryParams
    );

    logout(secureUrl, useAikyamEndpoint).catch(error => {
        if (error) {
            logger.warn('Unable to logout user', { error });
        }

        const loginPath = buildHsidPath({
            flowName: FlowNames.LOGIN,
            portalBrand,
            lang,
        });
        // bring user back to login page
        history.replace({
            pathname: loginPath,
        });
    });
};
// Settings page SignOut url which attaches HTTP_TARGET URL from config.
const settingsPageSignOut = (signOutTargetUrl, useAikyamEndpoint = false) => {
    logout(signOutTargetUrl, useAikyamEndpoint).catch(() => {
        // Unable to logoff user.
    });
};

/**
 * logout user for either ping access flow or regular flow
 * portal and lang will get defaulted if not passed
 *
 * @deprecated Use `{@see logout}` instead
 */
// eslint-disable-next-line default-param-last
export const logoutUser = (history, queryParams, portal, lang = 'en', useAikyamEndpoint = false, signOutTargetUrl) => {
    if (signOutTargetUrl) {
        settingsPageSignOut(signOutTargetUrl, useAikyamEndpoint);
    } else {
        pingAccessLogout(history, queryParams, portal, lang, useAikyamEndpoint);
    }
};
