import React from 'react';
import type { FC } from 'react';

import { useAppStore } from 'src/hooks';
import { Link } from 'src/UI/Link';
import { FlowNames } from 'src/app/Constants';
import { QaIds } from 'src/app/QaConstants';
import { withContent } from 'src/components/ContentProvider';
import type { WithContentProps } from 'src/components/ContentProvider';
import type { EmptyComponent } from 'src/app/types';
import AnalyticUtility, { handleAnalyticsTriggerError } from 'src/app/AnalyticUtility';
import type { LinkAnalyticsData } from 'src/app/AnalyticUtility';

import type { AccountRecoveryData } from '../types';
import { setAccountRecoveryData } from '../utils';

const mapContentToProps = {
    phoneRecoveryLabel: 'LblCantAccessNumber',
} as const;

type AccountRecoveryLinkContentProps = WithContentProps<typeof mapContentToProps>;

// NOTE at time of creation, all use cases of this component used the same `className` so no prop was added. If CSS class
// needs to be different per use, add a `className` prop.
type AccountRecoveryLinkPublicProps = {
    entryData: AccountRecoveryData;
    analyticsData: Pick<
        LinkAnalyticsData,
        'pageName' | 'uhcPageName' | 'uhcSiteSectionL3' | 'referringSite' | 'verificationMethod'
    >;
} & EmptyComponent;

type AccountRecoveryLinkProps = AccountRecoveryLinkPublicProps & AccountRecoveryLinkContentProps;

export const AccountRecoveryLink: FC<AccountRecoveryLinkProps> = ({
    entryData,
    analyticsData,

    // content
    phoneRecoveryLabel,
}) => {
    const { portalBrand, lang } = useAppStore();
    const fullAnalyticsData = {
        processName: 'trackLink',
        linkFriendlyName: 'ACCOUNT_RECOVERY_ENTRY_LINK',
        uhcLinkFriendlyName: 'ACCOUNT_RECOVERY_ENTRY_LINK',
        uhcSiteSectionL1: 'hsid',
        uhcSiteSectionL2: 'sign in',
        ...analyticsData,
    } as const;

    return (
        <Link
            className="has-text-centered button-like-link"
            id={QaIds.PHONE_RECOVERY_ACTION}
            hsidHref={{ flowName: FlowNames.ACCOUNT_RECOVERY, portalBrand, lang }}
            onClick={() => {
                AnalyticUtility.onLinkClickedData(fullAnalyticsData).catch(
                    handleAnalyticsTriggerError(fullAnalyticsData)
                );
                setAccountRecoveryData(entryData);
            }}
        >
            {phoneRecoveryLabel}
        </Link>
    );
};

export default withContent(mapContentToProps)(AccountRecoveryLink) as FC<AccountRecoveryLinkPublicProps>;
