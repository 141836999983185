import { SessionStorage } from 'src/app/Storage';
import format from 'src/utils/format';
import { FIELD_ERROR, SERVER_ERROR, LINK_FRIENDLY_NAMES } from 'src/app/AnalyticConstants';
import { logger } from 'src/app/Logger';
import { convertValidationResultsToFieldErrorItems } from 'src/UI/Form';

import type { IStorage } from 'src/app/Storage';
import type { SummaryErrorElement } from 'src/components/SummaryError/SummaryError';
import type { EmptyObject } from 'src/app/types';
import type { FieldErrorItem, FormFieldValidationResults } from 'src/UI/Form';
import { ServiceResponseCodes } from './Constants';

interface BaseAnalyticsData {
    pageName?: string;
    formName?: string;
    uhcPageName?: string;
    uhcFormName?: string;
    siteSectionL1?: string;
    siteSectionL2?: string;
    accountType?: string;
    hsidViewType?: string;
    /**
     * @siteSectionL3 depends on this below params
     1.	New user registration process
     2.	Step up legacy optum id registration process
     3.	Step up legacy myuhc registration process.
     4.	SSO assisted registration process.
     5.	Incomplete Registration fulfillment process
     */
    siteSectionL3?: string;
    uhcSiteSectionL1?: 'hsid';
    uhcSiteSectionL2?: string;
    uhcSiteSectionL3?: string;
    referringSite?: string;
}

export interface LoadAnalyticsData extends BaseAnalyticsData {
    referringPageSection?: string;
    processName: string;
    referringSite?: string;
    referringBusinessUnit?: 'uhc' | 'optum digital';
    isUhcUser?: boolean;
    uuid?: string;
    lang?: string;
    isAuthenticated?: boolean;
    groupNo?: string;
    eligibilityType?: string;
    recoveryType?: string;
    rememberMeChecked?: string;
    rememberDevice?: string;
    registrationFlow?: string;
    verificationMethod?: 'text' | 'call' | 'email';
    kpi?: {
        name: string;
        type: string;
        activity: string;
    };
    customURL?: string;
}

export interface LinkAnalyticsData extends BaseAnalyticsData {
    // trackExitLink is used for links that open a URL outside of HSID
    processName: 'trackLink' | 'trackExitLink';
    // TODO: update to use value of constant instead of key
    linkFriendlyName: keyof typeof LINK_FRIENDLY_NAMES;
    uhcLinkFriendlyName: keyof typeof LINK_FRIENDLY_NAMES;
    linkTarget?: string;
    additionalPlaceholderData?: Record<string, string>;
    referringSection?: string;
    /** Which TFA Auth Type is selected at the time, when appropriate */
    verificationMethod?: 'text' | 'call' | 'email';
}

interface AccordionLinkAnalyticsData extends BaseAnalyticsData {
    linkFriendlyName: string;
    uhcLinkFriendlyName: string;
    uuid?: string;
    isUhcUser: boolean;
}

interface FormSubmitAnalyticsData extends BaseAnalyticsData {
    processName: 'trackLink';
    linkFriendlyName: keyof typeof LINK_FRIENDLY_NAMES;
    uhcLinkFriendlyName: keyof typeof LINK_FRIENDLY_NAMES;
    isAuthenticated?: boolean;
    additionalPlaceholderData?: Record<string, string>;
    /** Which TFA Auth Type is selected at the time, when appropriate */
    verificationMethod?: 'text' | 'call' | 'email';
}

interface FieldErrorAnalyticsData extends BaseAnalyticsData {
    processName: 'errorMenu' | 'fieldTracking';
    errorType: string;
    errors: SummaryErrorElement[] | FieldErrorItem[];
    isAuthenticated: boolean;
    referringSite: string;
    uhcErrorType?: string; // TODO: so far unused
    uhcErrorReason?: string; // TODO: so far unused
}

export interface ServerErrorAnalyticsData extends BaseAnalyticsData {
    processName: 'pageErrors';
    errorCode?: string;
    errorType: string;
    isAuthenticated: boolean;
    uhcErrorType?: string;
    uhcErrorReason?: string;
}

export type PageData =
    | LoadAnalyticsData
    | LinkAnalyticsData
    | AccordionLinkAnalyticsData
    | FormSubmitAnalyticsData
    | FieldErrorAnalyticsData
    | ServerErrorAnalyticsData;

interface ContentSpecificData {
    errorReason?: string;
    errorFields?: string;
    errorFieldDescription?: string;
    hsidViewType?: string;
    language?: string;
    businessUnit?: string;
    website?: string;
}

type PageDataContent = Partial<PageData> & ContentSpecificData;

export type AnalyticUser = {
    userType: 'consumer';
    loginStatus: 'loggedin' | 'not loggedin';
    uuid?: string;
    accountType?: string;
};

interface PersistedAnalyticData {
    referringPageSection?: string;
}

interface CommonUhcData {
    siteSectionL1: string;
    siteSectionL2: string;
    siteSectionL3: string;
    businessUnit: 'uhc';
    website: string;
    formName?: string;
    accountType?: string;
}
const isCommonUhcData = (data: unknown): data is CommonUhcData => (data as CommonUhcData).website !== undefined;

export interface PageDataLayer {
    content?: PageDataContent;
    user?: AnalyticUser;
}

export interface UHCPageDataLayer extends PageDataLayer {
    eventInfo?: {
        eventName: string;
    };
}

const hasUHCPageData = (data: PageData): boolean =>
    typeof data.uhcPageName !== 'undefined' || typeof (data as LinkAnalyticsData).uhcLinkFriendlyName !== 'undefined';
const hasUser = (data: PageData): data is LoadAnalyticsData =>
    typeof (data as LoadAnalyticsData).isAuthenticated !== 'undefined' ||
    typeof (data as LoadAnalyticsData).uuid !== 'undefined';

const ANALYTIC_STORAGE_KEY = 'analyticsData' as const;

/* Promise for calling the analytic call once the script is loaded */
const satellite = new Promise<typeof window._satellite>((resolve, reject) => {
    const script = document.createElement('script');

    script.src = process.env.__ANALYTIC_URL ?? '';
    script.async = true;
    script.onload = () => {
        if (typeof window._satellite !== 'undefined') {
            resolve(window._satellite);
        } else {
            reject(new Error('Unable to find global variable from analytics script'));
        }
    };
    script.onerror = () => {
        reject(new Error('Unable to load analytics script.'));
    };

    document.head.appendChild(script);
}).catch(error => {
    // This just handles any unhandled Promise rejection errors
    if (error instanceof Error) {
        logger.error('Failed to load Analytics script', { error });
    }
});

const AnalyticUtility = {
    // used to keep page name available throughout
    pageName: undefined as string | undefined,
    uhcPagename: undefined as string | undefined,
    uhcCommonDataForEachPage: {} as CommonUhcData | EmptyObject,
    hsidViewType: '',
    cache: null as IStorage | null,
    referringPageSection: '',
    initialize({
        enableUHCDataLayer,
        isNativeApp = false,
        cache = SessionStorage,
    }: {
        enableUHCDataLayer: boolean;
        isNativeApp?: boolean;
        cache?: IStorage;
    }): void {
        // Called when user first time enters into our App
        window.pageDataLayer = {};
        this.hsidViewType = isNativeApp ? 'app' : 'portal';
        this.cache = cache;
        const persistedAnalyticsData = this.cache.get<PersistedAnalyticData>(ANALYTIC_STORAGE_KEY, { remove: true });
        if (persistedAnalyticsData) {
            this.referringPageSection = persistedAnalyticsData.referringPageSection || '';
        }
        window.publishPostPageData = (name, obj) => {
            // Copy object passed to global page data layer object
            Object.assign(window.pageDataLayer ?? {}, obj);
            try {
                const customEvent = new CustomEvent(`optum-${name}`, { detail: obj });
                document.body.dispatchEvent(customEvent);

                logger.debug(name, { analytics: obj });
            } catch (e) {
                // continue regardless of error
            }
        };

        if (enableUHCDataLayer) {
            window.uhc = {
                pageDataLayer: {},
            };
            window.uhcPublishPostPageData = (name, obj) => {
                // Copy object passed to global uhc page data layer object
                Object.assign(window.uhc?.pageDataLayer ?? {}, obj);

                try {
                    const customEvent = new CustomEvent(`uhc-${name}`, { detail: obj });
                    document.body.dispatchEvent(customEvent);

                    logger.debug(`{UHC} ${name}`, { analytics: obj });
                } catch (e) {
                    // continue regardless of error
                }
            };
        }
    },
    setUHCCommonDataForEachPage(data: LoadAnalyticsData | CommonUhcData): void {
        this.uhcCommonDataForEachPage = isCommonUhcData(data)
            ? data
            : {
                  siteSectionL1: data.uhcSiteSectionL1 ?? data.siteSectionL1 ?? '',
                  siteSectionL2: data.uhcSiteSectionL2 ?? data.siteSectionL2 ?? '',
                  siteSectionL3: data.uhcSiteSectionL3 ?? data.siteSectionL3 ?? '',
                  businessUnit: 'uhc',
                  website: data.referringSite ?? '',
                  accountType: data.accountType ?? '',
                  ...(data.uhcFormName || data.formName ? { formName: data.uhcFormName ?? data.formName } : {}),
              };
    },
    loadOptumPublishPageData(processName: string, pageContent?: PageDataContent, pageUser?: AnalyticUser): void {
        if (window.publishPostPageData && (pageContent || pageUser)) {
            window.publishPostPageData(processName, {
                content: pageContent,
                user: pageUser,
            });
        }
    },
    loadUhcPublishPageData(
        processName: string,
        uhcContent?: PageDataContent,
        uhcUser?: AnalyticUser,
        eventInfo?: { eventName: string }
    ): void {
        if (window.uhcPublishPostPageData && (uhcContent || uhcUser || eventInfo)) {
            window.uhcPublishPostPageData(processName, {
                content: uhcContent,
                user: uhcUser,
                eventInfo,
            });
        }
    },
    persistAnalyticData(data: PersistedAnalyticData): void {
        if (this.cache)
            this.cache.put(ANALYTIC_STORAGE_KEY, {
                referringPageSection: `${this.pageName || ''}:${data.referringPageSection || ''}`,
            });
    },
    registrationStartTriggerEvent(processName: string): Promise<void> {
        return satellite.then(satelliteTrackObject => {
            if (satelliteTrackObject) satelliteTrackObject.track(processName);
        });
    },
    sendAnalyticsData(
        processName?: string,
        pageContent?: PageDataContent,
        pageUser?: AnalyticUser,
        uhcContent?: PageDataContent,
        uhcUser?: AnalyticUser,
        eventInfo?: { eventName: string }
    ): Promise<void> {
        if (!processName) {
            return Promise.reject(
                new TypeError(`No processName supplied with Event:${eventInfo?.eventName ?? '[NO EVENT]'}`)
            );
        }

        return satellite.then(satelliteTrackObject => {
            this.loadOptumPublishPageData(processName, pageContent, pageUser);
            this.loadUhcPublishPageData(processName, uhcContent, uhcUser, eventInfo);
            if (satelliteTrackObject) satelliteTrackObject.track(processName);
        });
    },
    // don't add this in same line of business as it will impact others
    // used to trigger on load of a page
    onLoadData(data: LoadAnalyticsData): Promise<void> {
        const content = {
            pageName: data.pageName,
            siteSectionL1: data.siteSectionL1,
            siteSectionL2: data.siteSectionL2,
            siteSectionL3: data.siteSectionL3,
            businessUnit: 'optum',
            referringPageSection: data.referringPageSection || this.referringPageSection,
            referringSite: data.referringSite,
            website: 'hsid',
            language: data.lang,
            referringBusinessUnit: data.referringBusinessUnit,
            eligibilityType: data.eligibilityType,
            groupNo: data.groupNo,
            recoveryType: data.recoveryType,
            rememberDevice: data.rememberMeChecked,
            kpi: data.kpi,
            registrationFlow: data.registrationFlow,
            hsidViewType: this.hsidViewType,
            verificationMethod: data.verificationMethod,
        };

        const user = this.getUserFromData(data);
        let uhcContent, uhcUser;

        if (window.uhcPublishPostPageData && hasUHCPageData(data)) {
            this.setUHCCommonDataForEachPage(data);
            uhcContent = {
                ...this.uhcCommonDataForEachPage,
                pageName: data.uhcPageName,
                language: data.lang,
                customURL: data.customURL,
            };
            if (data.isUhcUser) {
                uhcUser = user;
            }
        }
        this.referringPageSection = '';
        return this.sendAnalyticsData(data.processName, content, user, uhcContent, uhcUser);
    },
    getParsedLinkFriendlyName(
        data: LinkAnalyticsData | FormSubmitAnalyticsData,
        key: 'linkFriendlyName' | 'uhcLinkFriendlyName'
    ): string {
        const valueToParse = data[key];
        const pageName = key === 'linkFriendlyName' ? this.pageName : this.uhcPagename;

        return format(this.LINK_FRIENDLY_NAMES[valueToParse], {
            PAGE_NAME: pageName,
            ...(data.additionalPlaceholderData ?? {}),
        });
    },
    getReferringPageSection(referringPageSection: string): string {
        if (!this.pageName) {
            logger.warn('AnalyticUtility.getReferringPageSection() called with no pageName set');
        }
        return `${this.pageName || ''}:${referringPageSection}`;
    },
    // used mainly to trigger href and links with in the page
    onLinkClickedData(data: LinkAnalyticsData): Promise<void> {
        const content = {
            pageName: this.pageName,
            linkFriendlyName: this.getParsedLinkFriendlyName(data, 'linkFriendlyName'),
            linkTarget: data.linkTarget,
            verificationMethod: data.verificationMethod,
        };
        // Casting the type of this.pageName because we know it will always be set
        this.referringPageSection = data.referringSection ? this.getReferringPageSection(data.referringSection) : '';
        let uhcContent;

        if (window.uhcPublishPostPageData) {
            uhcContent = {
                ...this.uhcCommonDataForEachPage,
                pageName: this.uhcPagename,
                linkFriendlyName: this.getParsedLinkFriendlyName(data, 'uhcLinkFriendlyName'),
            };
        }

        return this.sendAnalyticsData(data.processName, content, undefined, uhcContent);
    },
    trackAccordionLinks(data: AccordionLinkAnalyticsData): Promise<void> {
        const content = {
            pageName: this.pageName,
            businessUnit: 'optum',
            website: 'hsid',
            siteSectionL1: 'accordion',
            linkFriendlyName: data.linkFriendlyName,
        };
        const user = this.getUserFromData(data);
        let uhcContent, uhcUser;

        if (window.uhcPublishPostPageData) {
            uhcContent = {
                ...this.uhcCommonDataForEachPage,
                pageName: this.uhcPagename,
                linkFriendlyName: data.uhcLinkFriendlyName,
            };

            if (data.isUhcUser) {
                uhcUser = user;
            }
        }

        return this.sendAnalyticsData('trackAccordion', content, user, uhcContent, uhcUser);
    },
    // used when we change the page
    onFormSubmit(data: FormSubmitAnalyticsData): Promise<void> {
        const content = {
            pageName: data.pageName,
            linkFriendlyName: this.getParsedLinkFriendlyName(data, 'linkFriendlyName'),
            verificationMethod: data.verificationMethod,
        };
        const eventInfo = {
            eventName: 'form submit',
        };
        const user = this.getUserFromData(data);
        let uhcContent;

        if (window.uhcPublishPostPageData) {
            uhcContent = {
                ...this.uhcCommonDataForEachPage,
                pageName: data.uhcPageName,
                linkFriendlyName: this.getParsedLinkFriendlyName(data, 'uhcLinkFriendlyName'),
            };
        }

        return this.sendAnalyticsData(data.processName, content, user, uhcContent, undefined, eventInfo);
    },
    getErrorDescription(id: string): string {
        let errorDescription = '';
        const elm = document.getElementById(id);

        if (elm instanceof HTMLInputElement) {
            const val = elm.value;

            if (!val) {
                // if value is empty
                errorDescription = 'empty field';
            } else {
                // when value is entered but still has some error
                errorDescription = 'invalid Entry';
            }
        }

        return errorDescription;
    },
    fieldLevelErrorTracker(data: FieldErrorAnalyticsData): Promise<void> {
        let errorFields = '',
            errorFieldDescription = '';

        if (Array.isArray(data.errors)) {
            const fields: string[] = [],
                fieldDescriptions: string[] = [];

            data.errors.forEach((item, i) => {
                let label = item.elementLabel;

                if (label) {
                    // this code is specific for DOB to separate "-"
                    if (label.includes('-')) {
                        label = label.split('-')[1];
                    }

                    fields.push(`field${i + 1}:${label}`);

                    if (item.elementId) fieldDescriptions.push(`${label}:${this.getErrorDescription(item.elementId)}`); // not specified what type of message is required
                }
            });

            // join with '|' and trim if length > 255
            errorFields = fields.join('|').slice(0, 255);
            errorFieldDescription = fieldDescriptions.join('|').slice(0, 255);
        }

        const content = {
            pageName: data.pageName,
            errorType: data.errorType,
            errorFields: errorFields || undefined,
            referringSite: data.referringSite,
            errorFieldDescription: errorFieldDescription || undefined,
        };
        const user = this.getUserFromData(data);
        let uhcContent, uhcUser;

        if (window.uhcPublishPostPageData) {
            uhcContent = {
                ...this.uhcCommonDataForEachPage,
                pageName: data.uhcPageName,
                /**
                 * As of now no errorType change if in near future if need to update
                 * errorType use uhcErrorType
                 */
                errorType: data.uhcErrorType ? data.uhcErrorType : FIELD_ERROR.FIELD_VALIDATION,
                errorFields: errorFields || undefined,
                referringSite: data.referringSite,
                /**
                 * errorReason at Field level will be "Field validation Failed"
                 * in case if some thing specific required use uhcErrorReason
                 * not sure of errorReason as of now other than "Field validation Failed"?
                 */
                errorReason: data.uhcErrorReason ? data.uhcErrorReason : FIELD_ERROR.FIELD_VALIDATION,
                // "errorFieldDescription" : errorFieldDescription? errorFieldDescription: undefined
            };
            uhcUser = user;
        }

        return this.sendAnalyticsData(data.processName, content, user, uhcContent, uhcUser);
    },
    serverLevelErrorTracker(data: ServerErrorAnalyticsData): Promise<void> {
        const content = {
            pageName: data.pageName,
            businessUnit: 'optum',
            website: 'hsid',
            referringSite: data.referringSite,
            errorType: data.errorType,
            errorCode: data.errorCode,
        };

        // Take data.errorCode and find the key value in ServiceResponseCodes if it exists
        const errorCode = Object.entries(ServiceResponseCodes).find(([_, code]) => code === data.errorCode);
        if (errorCode) {
            content.errorCode = errorCode[0];
        }

        const user = this.getUserFromData(data);
        let uhcContent;

        if (window.uhcPublishPostPageData) {
            uhcContent = {
                ...this.uhcCommonDataForEachPage,
                pageName: data.uhcPageName,
                // with reference to US3026864 separating the line of business
                // for uhc from optum
                // it is opposite of optum for uhc
                errorType: data.uhcErrorType,
                errorReason: data.uhcErrorReason || data.errorType,
            };
        }

        return this.sendAnalyticsData(data.processName, content, user, uhcContent);
    },
    getUserFromData(data: PageData): AnalyticUser | undefined {
        return hasUser(data)
            ? {
                  userType: 'consumer',
                  loginStatus: data.isAuthenticated ? 'loggedin' : 'not loggedin',
                  uuid: data.uuid,
                  ...(data.accountType ? { accountType: data.accountType } : {}),
              }
            : undefined;
    },
    getReferralSite(portalBrand: string, targetPortal: string): string {
        // TODO need refining when more portal comes in
        // Target Portal is from config
        // Portal Code from URL (AEM channel code)
        switch (portalBrand) {
            // case "RX":
            // case "CAP":
            // case "WOMENSHEALTH":
            // case "MNR":
            case 'communityplan':
                return portalBrand.toLowerCase();
            default:
                return targetPortal.toLowerCase();
        }
    },
    setPageName(pageName: string): void {
        if (pageName) {
            this.pageName = pageName;
        }
    },
    setUHCPageName(pageName: string): void {
        if (pageName) {
            this.uhcPagename = pageName;
        }
    },
    FIELD_ERROR,
    SERVER_ERROR,
    LINK_FRIENDLY_NAMES,
};

export default AnalyticUtility;

export const handleAnalyticsTriggerError =
    (data: PageData) =>
    (error: Error): void => {
        logger.warn(`Error ${error.message} with Analytic Trigger Data`, { analytics: data });
    };

/**
 * Convert form fields errors using a Validator to analytics-friendly structure for field level `errors` property
 *
 * @param validationResults The validation results from a form
 * @returns An array of { elementId: string, elementLabel: string} for analytics purposes
 */
export const convertValidationResultsToAnalyticsFormFieldErrors = (
    validationResults: FormFieldValidationResults
): FieldErrorItem[] => convertValidationResultsToFieldErrorItems(validationResults);
