import React, { useState } from 'react';
import classNames from 'classnames';
import * as Select from '@radix-ui/react-select';

import type { ValueChangeEventHandler } from 'src/UI/Form';
import { FieldLabel } from 'src/UI/Form';
import { DropdownArrowIcon } from 'src/UI/Icon';
import { useEffectOnMounted } from 'src/hooks';

import { countryList } from './countries';
import { CountryCodeBGLocation, countryCodeMap, isoCountryCodeMap } from './utils';

import classes from './CountryCodeDropdownField.module.scss';

export type CountryCodeDropdownFieldProps = {
    name: string;
    label: string;
    subLabel?: string;
    emitIsoCode?: boolean;
    onValueChange: ValueChangeEventHandler;
    value: string;
};

const CountryFlag: React.FC<{ isoCountryCode: string }> = ({ isoCountryCode }) => (
    <span
        className={classes.CountryFlag}
        style={{
            backgroundPosition: `${CountryCodeBGLocation[isoCountryCode]}px`,
        }}
    />
);

const CountryCodeDropdownField: React.FC<CountryCodeDropdownFieldProps> = ({
    name,
    label,
    subLabel,
    onValueChange,
    emitIsoCode = false,
    value,
}) => {
    // Not guaranteed to exist if the library select is being weird
    const [displayedCountry, setDisplayedCountry] = useState(isoCountryCodeMap.US);

    useEffectOnMounted(() => {
        if (value === '1' || !value) {
            setDisplayedCountry(isoCountryCodeMap.US);
        } else {
            setDisplayedCountry(countryCodeMap[value] ?? isoCountryCodeMap.US);
        }
    });

    return (
        <div id={`${name}_container`}>
            <div className="has-rds-mb-8">
                <FieldLabel fieldId={name} className={classes.label} subLabel={subLabel}>
                    {label}
                </FieldLabel>
            </div>
            <Select.Root
                onValueChange={isoCode => {
                    /* For some ungodly reason this handler is called when the value is initialized via prop despite no user interaction.
                     * Need to check for existence of a value to prevent blanking out the field if the prop is initially not set.
                     */
                    if (isoCode) {
                        const countryCode = isoCountryCodeMap[isoCode]?.countryCode ?? isoCountryCodeMap.US.countryCode;
                        onValueChange(emitIsoCode ? isoCode : countryCode, name);
                        setDisplayedCountry(isoCountryCodeMap[isoCode]);
                    }
                }}
                value={displayedCountry?.isoCountryCode}
            >
                <Select.Trigger className={classNames(classes.SelectTrigger, 'has-rds-pv-8 has-rds-ph-16')} id={name}>
                    <Select.Value>
                        <div className={classes.SelectValue}>
                            <CountryFlag isoCountryCode={displayedCountry?.isoCountryCode} />
                            <span>+{displayedCountry?.countryCode}</span>
                        </div>
                    </Select.Value>
                    <Select.Icon asChild>
                        <DropdownArrowIcon className={classes.icon} />
                    </Select.Icon>
                </Select.Trigger>
                <Select.Portal>
                    <Select.Content className={classes.SelectContent} position="popper">
                        <Select.Viewport className={classes.SelectViewport}>
                            {countryList.map(country => (
                                <Select.Item
                                    key={country.isoCountryCode}
                                    value={country.isoCountryCode}
                                    className={classes.SelectItem}
                                >
                                    <Select.ItemText>
                                        <CountryFlag isoCountryCode={country.isoCountryCode} />
                                        {country.name} (+{country.countryCode})
                                    </Select.ItemText>
                                </Select.Item>
                            ))}
                        </Select.Viewport>
                    </Select.Content>
                </Select.Portal>
            </Select.Root>
        </div>
    );
};

export default CountryCodeDropdownField;
